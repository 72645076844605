<template>
  <div>
      <p>基本设置</p>
    <div class="content">
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="姓名">
        <el-input
          v-model="form.name"
          style="width: 200px; float: left"
        ></el-input>
      </el-form-item>
      <el-form-item label="昵称">
        <el-input
          v-model="form.guaName"
          style="width: 200px; float: left"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话">
        <el-input
          v-model="form.tel"
          style="width: 200px; float: left"
        ></el-input>
      </el-form-item>
      <el-form-item label="个人简介">
        <el-input
          type="textarea"
          v-model="form.remark"
          :autosize="{ minRows: 3, maxRows: 7 }"
          style="width: 230px; float: left"
        ></el-input>
      </el-form-item>
      <el-form-item label="地区选择">
        <el-select v-model="form.province" placeholder="请选择省份" @change="Change_Province">
          <el-option
            v-for="item in provinceList"
            :key="item.ID"
            :label="item.Name"
            :value="item.ID"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="form.city"
          placeholder="请选择区域"
          style="margin-left: 5px"
        >
          <el-option
            v-for="item in cityList"
            :key="item.ID"
            :label="item.Name"
            :value="item.ID"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="form.area"
          placeholder="请选择区"
          style="margin-left: 5px"
        >
          <el-option
            v-for="item in areaList"
            :key="item.ID"
            :label="item.Name"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" style="float: left"
          >更新基本信息</el-button
        >
      </el-form-item>
    </el-form>
     <!-- <div class="portrait">
      <p>头像</p>

   <el-upload
  class="avatar-uploader"
  action="https://jsonplaceholder.typicode.com/posts/"
  :show-file-list="false"
  :on-success="handleAvatarSuccess"
  :before-upload="beforeAvatarUpload">
  <img v-if="imageUrl" :src="imageUrl" class="avatar">
  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
</el-upload>

<el-button size="small" type="primary" @click="uploadFile" style="margin-top:5px">立即上传</el-button>
    </div> -->
  </div>

  </div>
</template>

<script>
import { regionInfo, setUserInfo } from '@/api/TeacherApi.js'
export default {
  data () {
    return {
      form: {
        name: '', // 姓名
        guaName: '', // 昵称
        remark: '', // 个人简介
        province: '', // 省
        city: '', // 市
        area: '', // 区域
        tel: '' // 电话
      },
      imageUrl: '',
      provinceList: [],
      cityList: [],
      areaList: [],
      filelists: [] // 上传的图片数组
    }
  },
  mounted () {
    this.regionInfo()// 获取省市区信息
  },
  methods: {
    onSubmit () { // 更新信息
      // console.log(this.form)
      setUserInfo(this.form).then(res => {
        // console.log(res)
        if (res.status.code == 1) {
          this.$message({
            message: '信息已更新',
            type: 'success'
          })
          this.form = {}
        } else {
          this.$message.error(res.status.msg)
        }
      }).catch(err => { console.log(err) })
    },
    regionInfo () { // 省市区信息
    const data = {
      aa:1
    }
      regionInfo(data).then(res => {
        this.provinceList = res.data.province
        this.cityList = res.data.city
        this.areaList = res.data.area
      }).catch(err => { console.log(err) })
    },
    Change_Province () { // 省份选择
        this.cityList = [];
    }
  }
}
</script>

<style lang="scss" scoped>
// .portrait {
  // float: right;
// }
.content{
  display: flex;
}
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
